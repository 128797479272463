.ButtonEnabled {
    width: "100px" !important;
    height: "30px" !important;
    background-color: #1e88e5;
    color: white;
    padding: 10px 35px;
    border-radius: 5px;
    font-size: 18px;
}

.ButtonDisabled {
    width: "100px" !important;
    height: "30px" !important;
    background-color: #757575;
    color: white;
    padding: 10px 35px;
    border-radius: 5px;
    font-size: 18px;
}