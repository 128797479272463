.InputField {
    padding: 15px 15px;
    font-size: 16px;
    width: 300px;
}

.MFAField {
    padding: 15px 15px;
    font-size: 16px;
    width: 150px;
}